import {
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
    Button
} from "reactstrap";
import { v4 as uuid } from 'uuid';

import React, { useState, useEffect } from 'react';
import { DatePicker } from 'reactstrap-date-picker'
export default function MeetingMinutesForm({formData, changeMode}) {



    const formatText = (text) => {
        return text.map(t => {
            return <> <div> {t} </div> <br/> </>
        })
    }

    return (
        <>
        <div style={{display:"flex",justifyContent:"flex-end"}}>   <Button color="primary"  style={{margin: "24px 0"}} onClick={() => changeMode("")}>Back </Button></div>
                         

            <div class="sploa-form-page">

                <div className='sploa-flex-center sploa-title-header'>SPLOA Board Meeting Minutes - {formData.boardMeetingDate}</div>


                <div>
                <Form>
                        {formData.bmAttending ?   <div className='sploa-form-item'> 
                            <span className={'sploa-form-item-header'}> Board Members Present: </span>
                            <span> {formatText(formData.bmAttending)} </span>
                        </div> : <></>}
                      
                        {formData.oAttendees && formData.oAttendees.length > 0 ?   <div className='sploa-form-item'> 
                            <span className={'sploa-form-item-header'}> Attendees: </span>
                            <span> {formatText(formData.oAttendees)} </span>
                        </div> : <></>}
                      
                               
                        {formData.intro ?   <div className='sploa-form-item'> 
                            <span className={'sploa-form-item-header'}> Call to Order: </span>
                            <span> {formatText(formData.intro)} </span>
                        </div> : <></>}
                      
                        {formData.openTime ?   <div className='sploa-form-item'> 
                            <span className={'sploa-form-item-header'}>  Open Forum: </span>
                            <span> {formatText(formData.openTime)} </span>
                        </div> : <></>}
                      
                        {formData.minutes ?   <div className='sploa-form-item'> 
                            <span className={'sploa-form-item-header'}> Approval of Minutes: </span>
                            <span> {formatText(formData.minutes)} </span>
                        </div> : <></>}
                      
                        {formData.presidentsReport ?   <div className='sploa-form-item'> 
                            <span className={'sploa-form-item-header'}> President's Report: </span>
                            <span> {formatText(formData.presidentsReport)} </span>
                        </div> : <></>}
                      
                        {formData.waterReport ?   <div className='sploa-form-item'> 
                            <span className={'sploa-form-item-header'}> Water Report: </span>
                            <span> {formatText(formData.waterReport)} </span>
                        </div> : <></>}
                      
                        {formData.roadAndEquipmentReport ?   <div className='sploa-form-item'> 
                            <span className={'sploa-form-item-header'}>  Road & Equipment Report: </span>
                            <span> {formatText(formData.roadAndEquipmentReport)} </span>
                        </div> : <></>}
    
                        {formData.architecturalReport ?   <div className='sploa-form-item'> 
                            <span className={'sploa-form-item-header'}>   Architectural Report: </span>
                            <span> {formatText(formData.architecturalReport)} </span>
                        </div> : <></>}
                     
                        {formData.lakesAndFishReport ?   <div className='sploa-form-item'> 
                            <span className={'sploa-form-item-header'}>      Lakes & Fish Report: </span>
                            <span> {formatText(formData.lakesAndFishReport)} </span>
                        </div> : <></>}

                        {formData.treasurersReport ?   <div className='sploa-form-item'> 
                            <span className={'sploa-form-item-header'}>        Treasurer's Report:</span>
                            <span> {formatText(formData.treasurersReport)} </span>
                        </div> : <></>}

                        {formData.newBusiness ?   <div className='sploa-form-item'> 
                            <span className={'sploa-form-item-header'}>        New Business:</span>
                            <span> {formatText(formData.newBusiness)} </span>
                        </div> : <></>}
                        {formData.outro ?   <div className='sploa-form-item'> 
                            <span className={'sploa-form-item-header'}>        Closing Remarks:</span>
                            <span> {formatText(formData.outro)} </span>
                        </div> : <></>}

                        {formData.author ?   <div className='sploa-form-item'> 
                            <span className={'sploa-form-item-header'}>  Author: </span> <br/>
                            <span> {formData.author} </span>
                        </div> : <></>}
           
                    </Form>
                    <div style={{display:"flex",justifyContent:"center"}}>   <Button color="primary"  style={{margin: "24px 0"}} onClick={() => changeMode("")}>Back </Button></div>
                </div>


            </div>
        </>
    );
}