
export default function ForSale() {
    return (
        <>
            <div class="sploa-info-page">
                <div className='sploa-flex-center sploa-title-header'>SPLOA Architectural Committee Permit Application Guidelines</div>


                <div>“No Dwelling or other improvements shall be erected, placed or altered on any premises on said property until a plot plan and building plans and specifications have been approved in writing by the Architectural Committee.”
                   &emsp;  -SPLOA Covenants
 </div>
                <br />
          
                    <div>The Covenants require an owner to submit an application to the Architectural Committee for approval, prior to building on a lot.<div> 
                    <br />
                    Click here to download the form: <a href="building/SPLOAArchitecturalCommitteePermitApplication.pdf" download>SPLOA Architectural Committee Permit Application</a>
                    <br />
                    <br />

                    Please use the Permit Application Form to provide the following information as part of your submittal:
                        </div>
                    <ol>
                        <li className="sploa-covenant-li">Name(s), home address, phone number(s) and email address(es) of Lot Owner(s)</li>
                        <li className="sploa-covenant-li"> Lot and Filing Numbers</li>
                        <li className="sploa-covenant-li">Description of proposed structure (if available attach photographs,
brochures, or drawings). Minimum 600 sq. ft.</li>

                        <li className="sploa-covenant-li">Plot plan of lot layout showing location of structure and any storage
or accessory structures. Indicate the proposed setbacks from roads, side lots, and rear lot lines.</li>

                        </ol>
                    <div>If you discover the contact information below is not current, please see our Officers page on our website (sploa.org). </div>
                    <br/>
                    <div>Send a copy of the permit application and attachments by mail or email to the followingcommittee members: </div>
                    <ul>
                        <br />
                        <span className="sploa-covenant-li">
                            <div> <b> Paula West </b> </div>
                            <div> (936) 414-2677</div>
                            <div>  1703 Jana Dr., Lufkin, TX 75904</div>
                            <div> <a href="mailto:waulapest1@yahoo.com" > waulapest1@yahoo.com </a></div>

                        </span>
                        <br/>
                        <span className="sploa-covenant-li">
                            <div><b>  Wally Munden </b></div>
                            <div>  (417) 865-0584</div>
                            <div>  350 S John Q Hammons Parkway, 12B, Springfield MO 65806</div>
                            <div>  <a href="mailto:wmunden2@sbcglobal.net" >  wmunden2@sbcglobal.net </a> </div>
                        </span>

                     
                        <br />
                        <span className="sploa-covenant-li">
                                <div> <b>Colleen Russ  </b> </div>
                            <div> (719) 250-6659</div>
                            <div> 67 Altadena Dr., Pueblo, CO 81005</div>
                            <div> <a href="mailto:tcbaruss@msn.com" > tcbaruss@msn.com </a> </div>
                        </span>
                    </ul>
                    <br />

                    <div>The $25 fee may be paid to the first name on the committee list (chairman).</div>
                    <br/>
<div>You will receive a call, text or e-mail that your application has been received. The decision of at least two members of the committee is required for approval or denial. If you do not receive a determination within 30 days from the date you are notified, the application is deemed approved.           </div>
                    </div>
                <br />

                (revised 2024)

            </div>
            <br />

        </>
    );
}