import {
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
    Button
} from "reactstrap";
import { v4 as uuid } from 'uuid';
import { useNavigate } from 'react-router-dom';

import React, { useState, useEffect } from 'react';

export default function Agenda({handleBasicAuth}) {
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [failed, setFailed] = useState(false);

    const handleChange = (event) => {
        const { id, value } = event.target;

        setPassword(value);
    };

    const handleSubmit = () => {
        if (handleBasicAuth(password)){
            console.log("here");
            navigate("/home");

        }else{
            setFailed(true);
        }
    }
    return (
        <>
            <div class="">
                <div className='sploa-login-body'>
                <Form>
                        <FormGroup>
                            <Label for="password">
                                Officer Password:
                            </Label>
                            <Input

                                onChange={handleChange}
                                id="password"
                                type="password"
                                name="password"
                            />
                        </FormGroup>
                        <div>
                        <Button color='primary' onClick={() => handleSubmit()}> Submit </Button>
                        </div>
                    </Form>

                </div>


            </div>
        </>
    );
}