import axios from 'axios';

const postData = async (key,data) => {
  try {
    const response = await axios.post( `https://ozyl8u5mbi.execute-api.us-west-1.amazonaws.com/staging/sploa?key=${key}`,data,{});
    return response.data;
  } catch (error) {
    // Handle error here
    throw error;
  }
};

export default postData;