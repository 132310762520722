
export default function EmergencyInfo() {
    return (
        <>
            <div class="sploa-info-page">

                <div className='sploa-flex-center sploa-title-header'>Emergency Information</div>


                <div>
                    <div>There has been some discussion about emergency situations on the Association and that each ambulance service in the area should have a key to our gate. When this was looked into we discovered that the ambulance services won't keep a key to our gate because there are too many places in the mountains that have lockedgates and they can't deal with that many keys.</div> <br />
                    <div>Each family should develop a plan to use for calling and meeting an ambulance in the event of an emergency. They should keep in mind the amount of time necessary for an ambulance to pickup and deliver a patient to a hospital from the Association.</div> <br />
                    <div>In the event of a health emergency, and when the family decides to call an ambulance, whoever calls for the ambulance will be responsible for getting someone to meet the ambulance at the gate and show them the way to the emergency situation.</div> <br />
                </div>


            </div>
        </>
    );
}