
export default function Regulations() {
    return (
        <>
            <div class="sploa-info-page">

                <div className='sploa-flex-center sploa-title-header'>Spanish Peaks Landowners Association Regulations</div>


                <div className='sploa-body'>
                    <div>Keep the main gate closed and locked at all times.</div>
                    <div>Each property owner is responsible for his/her family and guests.</div>
                    <div>All pets are to be controlled and not allowed to run on someone else's property.</div>
                    <div>Carry out your trash.</div>
                    <div>No trespassing on the Greenbelt areas.</div>
                    <div>Speed limit on the Association is 15 mph.</div>
                    <div>Enjoy your ATV, but remember to ride safely so that you are not a danger to others.</div>
                    <div>No camping or fires at the lakes.</div>
                    <div>Outdoor fires should occur only on one's own property.</div>
                    <div>Any outdoor fire on your own property should never be during periods of high fire danger.</div>
                    <div>There is a limit of six fish per fisherman, per day, with a limit of twenty-four per day for each lot or tract.</div>
                    <div>Cleaning of fish should occur only on one's own property.</div>
                    <div>There should be only one fishing pole per fisherman.</div>
                    <div>No firearms, fireworks, arrows, air rifles, BB guns or similar devices shall be discharged on the premises.</div>
                </div>

                <div className='sploa-flex-center sploa-title-header'>Outside burning rules and conditions.</div>
                <div className='sploa-body'>
                    <div>Because of the extreme fire danger this summer, all outside fires have been banned by the County Commissioners. We have been told that the ban will not be lifted this summer of 2023. This is a ban of all outside fires, contained or open burning. However, permits from the fire department are sometimes issued for certain burns during ban times.</div> <br />
                    <div>Contained fires are allowed during periods of less than severe conditions. Any burn must be attended with water hose until the fire is completely out.</div> <br />
                    <div>Contained fires are:<div />
                        <ul>
                            <li>Campfires with rock or metal rings around them.</li>
                            <li>Burning in a metal barrel the top covered with ¼ in. or less steel mesh. There should be bare ground under and around the contained fires. Smokers and charcoal, gas, or electric grills are not included in bans unless high winds or some other condition prevents safe use of a grill. Use common sense when using grills outside.</li>
                        </ul>
                        <div>No open burning (not contained) of tree branches, scrap lumber, trash, brush, structures and grass is ever allowed without a written permit.</div> <br />
                        <div>Permits may be obtained from:</div>
                        <ul>
                            <li> The Spanish Peaks Fire Department at 719-941-4107</li>
                            <li>  Fire Chief Larry Broach at 719-941-4505</li>
                            <li>  Assistant Fire Chief Wyatt Lisonbee at 719-859-0987</li>
                        </ul>
                        <div>Occasionally conditions occur that necessitate a ban on all outdoor fires issued by the governor, county commissioners, Spanish Peaks Volunteer Fire Department or the SPLOA Board of Directors. A fire ban means no burning outside!
                        </div><div>Exceptions: An electric, charcoal, or gas grill or anything that has a turn off switch.</div>
                    </div>
                </div>
            </div>
        </>
    );
}