
export default function Images() {
    return (
        <>
            <div className="sploa-info-page">
            <div className="sploa-flex-center">
                <div className='sploa-flex-center sploa-title-header'>Images</div> <br/>

                   Images will be added in the future! If you have any images you would like to to share, please send them to kyleaubreyford@gmail.com.

                </div>
            </div> <br/>
        </>
    );
}