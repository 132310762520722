import {
    Link
  } from "react-router-dom";
export default function InformationalPages() {
    return (
        < >
                
                <div className="sploa-flex-center sploa-title-header">Useful Information Links</div>

            <div class="sploa-flex-center sploa-info-margin">
                <div><Link to="/regulations" className="">Spanish Peaks Landowners Association Regulations</Link></div><br/>
                <div><Link to="/rules" className="">Association Rules</Link></div><br/>
                <div><Link to="/policy-unpaid-assessments" className="">SPLOA Policies Regarding Unpaid Assessments</Link></div><br/>
                <div><Link to="/emergency-info" className="">Emergency Information</Link></div><br/>


            </div>
        </>
    );
}