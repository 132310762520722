import './App.css';

import React, { useState } from 'react';
import Home from "./routes/home";
import Agenda from "./routes/agenda";
import Login from "./routes/login";

import InformationalPages from "./routes/informationalPages";

import Regulations from "./routes/infoPages/sploaRegulations";
import PolicyUnpaidAssessment from "./routes/infoPages/sploaPolicyUnpaidAssessments";
import Rules from "./routes/infoPages/sploaRules";
import EmergencyInfo from "./routes/infoPages/sploaEmergencyInfo";
import ForSale from "./routes/infoPages/sploaForSale";
import Images from "./routes/infoPages/sploaImages";
import Officers from "./routes/infoPages/sploaOfficers";
import Bylaws from "./routes/infoPages/sploaBylaws";
import Covenant from "./routes/infoPages/sploaCovenant";
import Membership from "./routes/infoPages/sploaMembership";
import SenateBills from "./routes/infoPages/SenateBills";
import Governance from "./routes/infoPages/sploaGovernance";
import BuildingPermit from "./routes/infoPages/sploaBuildingPermit";
import Archives from "./routes/infoPages/sploaArchives";




import bg from "./assets/bgblur.avif"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  Button,
  UncontrolledCarousel
} from 'reactstrap';

import * as ReactDOM from "react-dom/client";
import {

  Link,
  NavLink as RRNavLink,
  Routes,
  Route
} from "react-router-dom";
import { BrowserRouter } from 'react-router-dom'
import MeetingMinutes from './routes/infoPages/sploaMeetingMinutes';
function App() {
  const [loggedIn, setLoggedIn] = useState(false);
    
  const handleAuth = (value) => {
    console.log(value);
      if (value === "password"){
        setLoggedIn(true);
        return true;
      }
      return false;
  };


  return (
    <BrowserRouter>
      <div className="sploa-bg" style={{ backgroundImage: `url(${bg})` }}> </div>
      <div >
      {/* <div className="sploa-body" > */}

        <div>
          <div>
            <Navbar dark >
              <NavbarBrand to="/">SPLOA: Spanish Peaks Landowners Association</NavbarBrand>
              <Nav className="me-auto">
                <NavItem>
                  <Link to="/" className="nav-link sploa-nav-header-item">Home</Link>
                </NavItem>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle className="sploa-nav-header-item" nav caret>
                    Meeting Info
                  </DropdownToggle>
                  <DropdownMenu right>
                    <Link to="/agenda" className="dropdown-item sploa-nav-item">Agenda</Link>
                    <Link to="/meeting-minutes" className="dropdown-item sploa-nav-item">Meeting Minutes</Link>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle className="sploa-nav-header-item" nav caret>
                    News and Info
                  </DropdownToggle>
                  <DropdownMenu right>
                    <Link to="/informational-pages" className="dropdown-item sploa-nav-item">Important Information</Link>
                    <Link to="/for-sale" className="dropdown-item sploa-nav-item">Lots and Cabins</Link>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle className="sploa-nav-header-item" nav caret>
                    Official Docs
                  </DropdownToggle>
                  <DropdownMenu right>
                    <Link to="/bylaws" className="dropdown-item sploa-nav-item">Bylaws</Link>
                    <Link to="/covenants" className="dropdown-item sploa-nav-item">Covenants</Link>

                    <Link to="/membership" className="dropdown-item sploa-nav-item">Membership</Link>
                    <Link to="/senate-bill" className="dropdown-item sploa-nav-item">Senate Bill 05-100</Link>
                    <Link to="/archives" className="dropdown-item sploa-nav-item">Archives, Meetings, and Documents</Link>
                    <Link to="/building-permit" className="dropdown-item sploa-nav-item">Architectural/Building Permit</Link>
                    <Link to="/colorado-common-interest-interest-ownership-act" className="dropdown-item sploa-nav-item">Colorado Common Interest Ownership Act</Link>

                  </DropdownMenu>
                </UncontrolledDropdown>
                <NavItem>
                  <Link to="/images" className="nav-link sploa-nav-header-item">Images</Link>
                </NavItem>
                <NavItem>
                  <Link to="/officers"className="nav-link sploa-nav-header-item">Officers</Link>
                </NavItem>
                <NavItem>
                {loggedIn ?           <NavbarText className="nav-link" style={{ color: "white"}}>Logged In</NavbarText> : <Link to="/login" className="nav-link sploa-nav-header-item">Log In</Link>}
                </NavItem>
              </Nav>
              {/* <NavbarText>Login</NavbarText> */}
            </Navbar>
          </div>

        </div>
        <div className='sploa-page'>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/home" element={<Home />}></Route>
          <Route path="/agenda" element={<Agenda loggedIn={loggedIn}/>}></Route>
          <Route path="/informational-pages" element={<InformationalPages />}></Route>
          <Route path="/regulations" element={<Regulations />}></Route>
          <Route path="/rules" element={<Rules />}></Route>
          <Route path="/policy-unpaid-assessments" element={<PolicyUnpaidAssessment />}></Route>
          <Route path="/emergency-info" element={<EmergencyInfo />}></Route>
          <Route path="/for-sale" element={<ForSale />}></Route>
          <Route path="/images" element={<Images />}></Route>
          <Route path="/meeting-minutes" element={<MeetingMinutes loggedIn={loggedIn} />}></Route>
          <Route path="/login" element={<Login  handleBasicAuth={handleAuth}/>}></Route>
                      <Route path="/officers" element={<Officers />}></Route>
                      <Route path="/bylaws" element={<Bylaws />}></Route>
                      <Route path="/covenants" element={<Covenant />}></Route>
                      <Route path="/membership" element={<Membership />}></Route>
                      <Route path="/senate-bill" element={<SenateBills />}></Route>
                      <Route path="/colorado-common-interest-interest-ownership-act" element={<Governance />}></Route>
                      <Route path="/building-permit" element={<BuildingPermit />}></Route>
                      <Route path="/archives" element={<Archives />}></Route>

        </Routes>
        </div>
      </div>
    </BrowserRouter>


  );
}

export default App;
