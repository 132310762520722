
export default function ForSale() {
    return (
        <>
            <div className="sploa-info-page">
            <div className="sploa-flex-center">
                <div className='sploa-flex-center sploa-title-header'>Properties for Sale</div> <br/>

                    <div>Lot 62 Filling 2.52 acre</div>
                    <div>$18,000.00 </div>
                    <div>Lot for sale with great view!</div>

                    <div>Contact: Dale Abesamis @ (806) 544-4813 </div>
                    <br />

                    <div>Cabin For Sale!</div>
                    <a href="https://my.flexmls.com/KerryCampbell/search/listing_categories/Active/listings/20230607195715863938000000?from_filter=false">Click here to view!</a>
                    <br />

                    <div>Lot For Sale!</div>
                    <a href="https://my.flexmls.com/KerryCampbell/search/listing_categories/Active/listings/20230810201114956320000000?from_filter=false">Click here to view!</a>
                    <br />

                </div>
            </div> <br/>
        </>
    );
}