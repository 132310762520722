import { Document, Page, pdfjs } from 'react-pdf';
import React, { useState, useEffect } from 'react';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import {
    Button
} from 'reactstrap';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
};
export default function Bylaws() {
    const [pdfName, setPdfName] = useState("");

    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    const [viewAll, setViewAll] = useState(false)

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    return (
        <>
            <div>
            {numPages && <div>
                    <div className='sploa-pdf-button'>
                    <Button color="secondary" onClick={() => setViewAll(!viewAll)} className={'sploa-minute-meeting-button'}>  {viewAll ? "View Single Page" : "View All Pages"} </Button >
                </div>
                    {!viewAll && <div className='sploa-pdf-buttons'>
                        <Button disabled={pageNumber <= 1} color="primary" onClick={() => setPageNumber(pageNumber - 1)} className={'sploa-minute-meeting-button'}>  Previous Page </Button >
                        {`Page ${pageNumber} of ${numPages}`}
                        <Button disabled={pageNumber > numPages-1}color="primary" onClick={() => setPageNumber(pageNumber + 1)} className={'sploa-minute-meeting-button'}>  Next page </Button >
                </div>}

            </div>}
            <div className="sploa-pdf-page">
                <Document file={`bylaws.pdf`} onLoadSuccess={onDocumentLoadSuccess} options={options}>
                    {viewAll ?
                        Array.from(new Array(numPages), (el, index) => (
                            <Page className={''} key={`page_${index + 1}`} pageNumber={index + 1} />
                        ))
                        :
                        <Page className={''} pageNumber={pageNumber} />}

                </Document>
                </div>
                </div>
        </>
    );
}