import MeetingMinutesForm from '../infoPages/sploaMeetingMinutesForm';
import MeetingMinutesViewForm from '../infoPages/sploaMeetingMinutesViewForm';
import axios from 'axios';
import postData from '../../utils/service'

import React, { useState, useEffect } from 'react';
import {
    Button,
    Spinner
} from 'reactstrap';





import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';


const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
};


export default function MeetingMinutes({ loggedIn }) {
    const [data, setData] = useState([]);

    const [meetingMinutesList, setMeetingMinutesList] = useState({});
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await postData("retrieveFormsByYear", { years: [2023, 2022] });
                setMeetingMinutesList(response);

            } catch (error) {
                console.error('Error fetching meeting minutes:', error);
                // Handle the error as needed
            }
        };

        fetchData();
    }, {});

    const [viewId, setViewId] = useState("");
    const [mode, setMode] = useState("");
    const [currentView, setCurrentView] = useState({});
    const addMeetingMinutes = async () => {
        const response = await postData("retrieveFormsByYear", { years: [2023, 2022] });
        setMeetingMinutesList(response);
        setMode("");
        // do something with value in parent component, like save to state
    }

    const findObjectByFormId = (data, formId) => {
        for (const [year, meetingMinutesList] of Object.entries(data)) {
            const foundObject = meetingMinutesList.find(
                (meetingMinutes) => meetingMinutes.formId === formId
            );
            if (foundObject) {
                return foundObject;
            }
        }
        return null; // Return null if the formId is not found
    }


    const updateMeetingMinutes = async (meetingMinutesObject) => {
        await postData("save", meetingMinutesObject)
        const response = await postData("retrieveFormsByYear", { years: [2023, 2022] });
        setMeetingMinutesList(response);
        setMode("");

        // do something with value in parent component, like save to state
    }

    const updateViewId = (id) => {
        setViewId(id);
        setCurrentView(findObjectByFormId(meetingMinutesList, id))
        console.log(findObjectByFormId(meetingMinutesList, id))
        // do something with value in parent component, like save to state
    }

    const updateStatus = async (id) => {
        setViewId(id);
        console.log("settingViewId", id)
        const form = findObjectByFormId(meetingMinutesList, id)
        form.status = "final";
        await postData("save", form)
        const response = await postData("retrieveFormsByYear", { years: [2023, 2022] });
        setMeetingMinutesList(response);

        // do something with value in parent component, like save to state
    }

    const editFormat = (form) => {
        const editedObj = {};

        Object.keys(form).forEach(key => {
            const value = form[key];
            if (Array.isArray(value) && value.every(item => typeof item === 'string')) {
                editedObj[key] = value.join('\n\n');
            } else {
                editedObj[key] = value;
            }
        });

        return editedObj;
    }

    const updateMode = (mode) => {
        setMode(mode);
    }
    return (
        <>
            <div class="sploa-info-page">
                {mode === "create" ? <MeetingMinutesForm addForm={addMeetingMinutes} changeMode={updateMode}></MeetingMinutesForm> : <></>}

                {mode === "" ? <>
                    <div className='sploa-flex-center sploa-title-header'>Meeting Minutes</div>
                    <div>

                        <div>The Board of Directors meetings are held each month of the summer. The minutes of the each meeting will be posted here soon after the meeting, but will not be officially approved until the following meeting. Any changes that are made until then will be updated here as they happen.</div>
                        <br />

                        {loggedIn && <div>
                            <span>To add a new form, click here.</span>
                            <Button color="primary"  onClick={() => updateMode("create")} className={'sploa-minute-meeting-button'}> CREATE </Button>
                        </div>}
                     
                        <div className="sploa-year-header"> 2024</div>
                        <br />
                         <div  className='sploa-minute-meeting-row'>
                            <div>    Board Meeting: 06/04/2024 by Kim Roten </div>
                            <div>  <a className={'btn btn-primary '} href="/meetingminutes/0612024.pdf" download>Download</a>
                            </div>
                        </div>
                  
                        <br />
                        {Object.entries(meetingMinutesList).length === 0 && <Spinner color="primary" style={{
                            height: '4rem',
                            width: '4rem',
                            display: 'flex',
                            margin: 'auto'
                        }}>
                        </Spinner>}
                        {Object.entries(meetingMinutesList)
                            .sort(([yearA], [yearB]) => yearB - yearA)
                            .map(([year, meetingMinutesYearList]) => {
                                return (
                                    <div key={year}>
                                        <div className="sploa-year-header"> {year}</div>
                                        {year == "2023" && <>


                                            <div className='sploa-minute-meeting-row'>
                                                <div>    Annual Meeting: 09/02/2023 by Kim Roten </div>
                                                <div>  <a className={'btn btn-primary '} href="/meetingminutes/annual09022023.pdf" download>Download</a>
                                                </div>
                                            </div>
                                            <div className='sploa-minute-meeting-row'>
                                                <div>    Board Meeting: 09/02/2024 by Kim Roten </div>
                                                <div>  <a className={'btn btn-primary ' } href="/meetingminutes/members09022023.pdf" download>

                                                    Download</a>
                                                </div>
                                            </div>
                                        </>

                                        }
                                        {meetingMinutesYearList.sort((a, b) => new Date(b.boardMeetingDate).getTime() - new Date(a.boardMeetingDate).getTime()).map(object => {
                                            if (object.status === "pending" && loggedIn) {
                                                return <div key={object.formId} className='sploa-minute-meeting-row'>
                                                    <div> {object.type === "annual" ? "Annual Meeting:" : "Board Meeting:"} {object.boardMeetingDate} by {object.author} </div>
                                                    <div>
                                                        <Button color="warning"  className={'sploa-minute-meeting-button'} onClick={() => { updateViewId(object.formId); updateMode('edit') }}> Edit </Button>
                                                        <Button color="success" className={'sploa-minute-meeting-button'} onClick={() => { updateStatus(object.formId) }}> APPROVE </Button>
                                                        <Button color="primary" onClick={() => { updateViewId(object.formId); updateMode('view') }} className={'sploa-minute-meeting-button'}> View </Button>

                                                    </div>
                                                </div>
                                            }
                                            else if (object.status === "final") {
                                                return <div key={object.formId} className='sploa-minute-meeting-row'>
                                                    <div> {object.type === "annual" ? "Annual Meeting:" : "Board Meeting:"} {object.boardMeetingDate} by {object.author} </div>
                                                    <div>
                                                        <Button color="primary"  onClick={() => { updateViewId(object.formId); updateMode('view') }} className={'sploa-minute-meeting-button'}> View </Button>

                                                    </div>
                                                </div>
                                            }
                                        })}
                                    </div>
                                );
                            })}




                    </div></> : <></>}
                {mode === "view" && currentView ? <>
                    <MeetingMinutesViewForm changeMode={updateMode} formData={currentView} />
                </> : <></>}
                {mode === "edit" && currentView ? <>
                    <MeetingMinutesForm addForm={updateMeetingMinutes} changeMode={updateMode} prevFormData={editFormat(currentView)}></MeetingMinutesForm>
                </> : <></>
                }




            </div>
        </>
    );
}