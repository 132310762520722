
export default function Rules() {
    return (
        <>
            <div class="sploa-info-page">

                <div className='sploa-flex-center sploa-title-header'>Association Rules Page</div> <br/>


                <div className='sploa-body'>

                    <div>Responsibilities of Spanish Peak Landowners Association Members</div> <br/>

                    <div>You are responsible for anyone staying in your cabin. Please be sure all guests are aware of and committed to following all of the rules.</div> <br/>

                    <div>Make sure you know where your nearest in-line shut off valve is (in the white pipe that your tap leads from). If you have some sort of water leak or break you will need to shut off your water supply to avoid wasting water and then seek help. Any time you are unsure how to connect your cabins water line, operate our new fittings or have any other water related problems, please ask someone to help you.</div> <br/>

                    <div>Here are some possibilities:</div> <br/>
                    <ul>
                        <li>Scott Ford (562) 665-4541 </li>
                        <li>Don Dorough (972) 658-3592 </li>
                        <li>Houston Ewing (806) 678-6004 </li>

                    </ul>

                    <div>Fix all leaks on your property. An amazing amount of water can be lost through a very small leak.</div> <br/>

                    <div>Turn your water off at the main line when you leave your property for a day or more.</div> <br/>

                    <div>Building permits are required for all construction on your property.</div> <br/>

                    <div>Always ask someone above before filling your water tank! This is much more important than you think.</div> <br/>

                    <div>The state does not allow us to wash vehicles with our association water.</div> <br/>

                    <div>All pets should be controlled and not allowed to run free.</div> <br/>

                    <div>The Association speed limit is 15 MPH.</div> <br/>

                    <div>Do not ride on the greenbelt areas or on the property of others.</div> <br/>

                    <div>No outdoor fires are allowed anywhere on the Association.</div> <br/>

                    <div>One (1) fishing pole per fisherman.</div> <br/>
                    <div>Limit of 6 fish per fisherman per day with a limit of twenty-four (24) fish per day per lot or tract.</div> <br/>
                    <div>Do not clean fish at the lakes.</div> <br/>
                    <div>Do not leave any trash at the lakes or outside on your property.</div> <br />

                </div> <br/>
            </div> <br/>
        </>
    );
}