
export default function ForSale() {
    return (
        <>
            <div class="sploa-info-page">
                <div className='sploa-flex-center sploa-title-header'>SPLOA Members may request membership data from Henry Ford at rhford1@mac.com.</div>               


            </div> <br/>
        </>
    );
}