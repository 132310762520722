import { Document, Page, pdfjs } from 'react-pdf';
import React, { useState, useEffect } from 'react';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import {
    Button
} from 'reactstrap';
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';


const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
};
export default function Bylaws() {
    const [pdfName, setPdfName] = useState("");

    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    const [viewAll, setViewAll] = useState(false)

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    return (
        <>
            <div>
                <div className='sploa-margin-16'><Button color="primary" onClick={() => { setPageNumber(1); setPdfName("meetings-2000-2002") }} className={'sploa-minute-meeting-button'}>  View </Button >Meetings and documents from 2000 to 2002 </div>
                <div className='sploa-margin-16'><Button color="primary" onClick={() => { setPageNumber(1); setPdfName("meetings-2003-2005") }} className={'sploa-minute-meeting-button'}>  View </Button >Meetings and documents from 2003 to 2005 </div>
                <div className='sploa-margin-16'><Button color="primary" onClick={() => { setPageNumber(1); setPdfName("meetings-2006-2008") }} className={'sploa-minute-meeting-button'}>  View </Button >Meetings from 2006 to 2008 </div>
                <div className='sploa-margin-16'><Button color="primary" onClick={() => { setPageNumber(1); setPdfName("meetings-2009-2011") }} className={'sploa-minute-meeting-button'}>  View </Button >Meetings from 2009 to 2011</div>
                <div className='sploa-margin-16'><Button color="primary" onClick={() => { setPageNumber(1); setPdfName("meetings-2012-2014") }} className={'sploa-minute-meeting-button'}>  View </Button >Meetings from 2012 to 2014</div>
                <div className='sploa-margin-16'><Button color="primary" onClick={() => { setPageNumber(1); setPdfName("meetings-2015-2018") }} className={'sploa-minute-meeting-button'}>  View </Button >Meetings from 2015 to 2018</div>
                <div className='sploa-margin-16'><Button color="primary" onClick={() => { setPageNumber(1); setPdfName("meetings-2019-2021") }} className={'sploa-minute-meeting-button'}>  View </Button >Meetings from 2019 to 2021</div>
                {numPages && <div >
                    <div className='sploa-pdf-button'>
                        <Button  color="secondary" onClick={() => setViewAll(!viewAll)} className={'sploa-minute-meeting-button'}>  {viewAll ? "View Single Page" : "View All Pages"} </Button >
                    </div>
                    {!viewAll && <div className='sploa-pdf-buttons'>
                        <Button disabled={pageNumber <= 1}color="primary" onClick={() => setPageNumber(pageNumber - 1)} className={'sploa-minute-meeting-button'}>  Previous Page </Button >
                        {`Page ${pageNumber} of ${numPages}`}
                        <Button disabled={pageNumber > numPages - 1} color="primary" onClick={() => setPageNumber(pageNumber + 1)} className={'sploa-minute-meeting-button'}>  Next page </Button >
                    </div>}

                </div>}
                <div className="sploa-pdf-page">
                    {pdfName ? <Document file={`${pdfName}.pdf`} onLoadSuccess={onDocumentLoadSuccess} options={options}>
                        {viewAll ?
                            Array.from(new Array(numPages), (el, index) => (
                                <Page className={''} key={`page_${index + 1}`} pageNumber={index + 1} />
                            ))
                            :
                            <Page className={''} pageNumber={pageNumber} />}

                    </Document> : <div> </div>}
                </div>
            </div>
        </>
    );
}