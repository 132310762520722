
export default function ForSale() {
    return (
        <>
            <div class="sploa-info-page">
                <div className="sploa-flex-center sploa-title-header"	>Senate Bill 05-100</div>               
                <span>
                    <span>For a more complete but complex explanation of the new bill click </span>
                    <a href="https://www.cohoalaw.com/from-capitol-hilllegislation/36-s-b-100-what-you-need-to-know-about-colorados-newest-law-affecting-common-interest-communities"> here</a>
                    <span>. </span>
                </span>
                <br />
                <br />

                <div>NOTICE TO ALL SPLOA MEMBERS </div>
                 <br />
                <div>In 2005 the Senate Bill 05-100 was signed into law. This legislation pertains to the governance of Colorado`s common interest communities (homeowners associations). While mainly targeting the primary residences of homeowners living in a common community, generally in an urban setting, as a homeowners association, albeit secondary dwellings, SPLOA is required to comply in areas pertaining to us. The following information is provided to inform you of provisions which may be of interest to you.  </div>
                <ol>
                    <li className="sploa-covenant-li"> The Association&apos; legal name is: SPANISH PEAKS LANDOWNER&apos;S ASSOCIATION. The current legal mailing address is P. O.  Box 336273, Greeley, Colorado 80633. The names, telephone numbers and addresses of current board members and committee members may be found in the link to 	&quot;Officers&quot; on the Web side. The Web site address is: www. sploa. org. </li>
                    <li className="sploa-covenant-li">The initial date of recording the Protective Covenants was May 25, 1971, Los Animas County, Book 733, page 296. , reception num ber 487889. </li>
                    <li className="sploa-covenant-li">The Association&#39;s fiscal year begins January 1. The financial statements for the immediate preceding year and current budget, as well as a list of members, are available on the Web site. </li>
                    <li className="sploa-covenant-li">SPLOA carries commercial general liability and property insurance through Addison Insurance Company, a subsidiary of United Fire and Casualty, policy #60035637. Liability limits are $500,000 for auto and $1,000,000 for general liability, with $1,000 deductible. The policy currently renews on September 11 of each year. </li>
                    <li className="sploa-covenant-li">The Association&#39;s governance policies are located within the Covenants and By-Laws, as provided on the Web site. </li>
                    <li className="sploa-covenant-li">All Association records are available to lot owners upon written request, within a reasonable time upon receiving the request. Requests may be addressed to any current Board Member. The request must be made in good faith, for a proper purpose, and describe with reasonable detail what records are needed and why. Requested documents must be relevant to the lot owner&#39;s stated purpose for the request. </li>
                    <li className="sploa-covenant-li">Seller&#39;s disclosure of Buyer&#39;s responsibilities to the Association and Requirement for Architectural Approval: Applicable to EVERY purchase and sale of property within the Association. The required Seller&#39;s Disclosure to the Buyer is available upon written request to the Association&#39;s mailing address, as above. The Seller is required to obtain from the buyer a signed acknowledgment of receipt of required information and disclosures, and to provide SPLOA a copy of the signed statement within a reasonable amount of time. </li>

                </ol>


            </div> <br/>
        </>
    );
}