import {
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
    Button,
    Toast,
    ToastHeader,
    ToastBody
} from "reactstrap";
import { v4 as uuid } from 'uuid';
import postData from '../../utils/service'
import React, { useState, useEffect } from 'react';
import { DatePicker } from 'reactstrap-date-picker'
export default function MeetingMinutesForm({ addForm, changeMode, prevFormData }) {
    const [formData, setFormData] = useState(prevFormData ? prevFormData : {
        "formId": uuid(),
        "boardMeetingDate": new Date().toLocaleString('en-US', {
            timeZone: 'America/Denver',
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
        }),
        "status": "pending"
    });


    const [loading, setLoading] = useState(false)


    const handleChange = (event) => {
        const { id, value } = event.target;

        setFormData((prevFormData) => ({ ...prevFormData, [id]: value }));
        console.log(formData)
    };
    const handleDateChange = (value, formattedValue) => {
        const dateValue = new Date(value).toLocaleString('en-US', {
            timeZone: 'America/Denver',
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
        })
        setFormData((prevFormData) => ({ ...prevFormData, ["boardMeetingDate"]: dateValue }));
        console.log('Form data:', formData);

    }
    const handleSubmit = async (event) => {
        setLoading(true)
        event.preventDefault();
        console.log(formData);

        const editedObj = {};

        Object.keys(formData).forEach(key => {
            const value = formData[key];
            if (['author', 'formId', 'status', 'boardMeetingDate', 'type'].includes(key)) {
                editedObj[key] = value;
            } else {

                editedObj[key] = value.split(/[\n]{2}/);
            }
        });


        await postData("save", editedObj)
        addForm(editedObj);
        console.log('Submitting Form data:', editedObj);
    };


    return (
        <>
            <div class="sploa-form-page">

                <div className='sploa-flex-center sploa-title-header'>Meeting Minutes Form</div>


                <div>
                    <Form>
                        <FormGroup>
                            <Label for="author">
                                Author:
                            </Label>
                            <Input

                                onChange={handleChange}
                                id="author"
                                name="author"
                                value={formData.author}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="author">
                                Meeting Type:
                            </Label>
                            <Input

                                onChange={handleChange}
                                id="type"
                                type="select"
                                value={formData.type}
                            >

                                <option value="">General Board Meeting</option>
                                <option value="annual">Annual Meeting</option>
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>Board Meeting Date:</Label>
                            <DatePicker id="datePicker"
                                value={formData.boardMeetingDate}
                                disabled={prevFormData}
                                onChange={(v, f) => handleDateChange(v, f)} />
                        </FormGroup>


                        <FormGroup>
                            <Label for="bmAttending">
                                Board Members Present:
                            </Label>
                            <Input
                                onChange={handleChange}
                                id="bmAttending"
                                name="bmAttending"
                                type="textarea"
                                className="sploa-form-textarea"
                                // style={{ minHeight: formData.bmAttending ? formData.bmAttending.split("\n").length * 48 + "px"  }}

                                value={formData.bmAttending}

                            />
                        </FormGroup>


                        <FormGroup>
                            <Label for="oAttendees">
                                Other Attendees
                            </Label>
                            <Input
                                onChange={handleChange}
                                id="oAttendees"
                                name="oAttendees"
                                type="textarea"
                                className="sploa-form-textarea"
                                // style={{ minHeight: formData.oAttendees.split("\n").length * 48 + "px" }}

                                value={formData.oAttendees}

                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="intro">
                                Call to Order:
                            </Label>
                            <Input
                                onChange={handleChange}
                                id="intro"
                                name="intro"
                                type="textarea"
                                className="sploa-form-textarea"
                                // style={{ minHeight: formData.intro.split("\n").length * 48 + "px" }}

                                value={formData.intro}

                            />
                        </FormGroup>


                        <FormGroup>
                            <Label for="openTime">
                                Open Forum:
                            </Label>
                            <Input
                                onChange={handleChange}
                                id="openTime"
                                name="openTime"
                                value={formData.openTime}
                                // style={{ minHeight: formData.openTime.split("\n").length * 48 + "px" }}

                                type="textarea"
                                className="sploa-form-textarea"
                            />
                        </FormGroup>


                        <FormGroup>
                            <Label for="minutes">
                                Approval of Minutes:
                            </Label>
                            <Input
                                onChange={handleChange}
                                id="minutes"
                                name="minutes"
                                value={formData.minutes}
                                // style={{ minHeight: formData.minutes.split("\n").length * 48 + "px" }}

                                type="textarea"
                                className="sploa-form-textarea"
                            />
                        </FormGroup>


                        <FormGroup>
                            <Label for="presidentsReport">
                                President's Report:
                            </Label>
                            <Input
                                onChange={handleChange}
                                id="presidentsReport"
                                name="presidentsReport"
                                type="textarea"
                                className="sploa-form-textarea"
                                // style={{ minHeight: formData.presidentsReport.split("\n").length * 48 + "px" }}

                                value={formData.presidentsReport}

                            />
                        </FormGroup>



                        <FormGroup>
                            <Label for="waterReport">
                                Water Report:
                            </Label>
                            <Input
                                onChange={handleChange}
                                id="waterReport"
                                name="waterReport"
                                type="textarea"
                                className="sploa-form-textarea"
                                // style={{ minHeight: formData.waterReport.split("\n").length * 48 + "px" }}

                                value={formData.waterReport}

                            />
                        </FormGroup>



                        <FormGroup>
                            <Label for="roadAndEquipmentReport">
                                Road & Equipment Report:
                            </Label>
                            <Input
                                onChange={handleChange}
                                id="roadAndEquipmentReport"
                                name="roadAndEquipmentReport"
                                type="textarea"
                                className="sploa-form-textarea"
                                value={formData.roadAndEquipmentReport}
                            // style={{ minHeight: formData.roadAndEquipmentReport.split("\n").length * 48 + "px" }}

                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="architecturalReport">
                                Architectural Report:
                            </Label>
                            <Input
                                contentEditable={true}
                                onChange={handleChange}
                                id="architecturalReport"
                                name="architecturalReport"
                                type="textarea"
                                className="sploa-form-textarea"
                                value={formData.architecturalReport}
                            // style={{ minHeight: formData.architecturalReport.split("\n").length * 48 + "px" }}


                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="lakesAndFishReport">
                                Lakes & Fish Report:
                            </Label>
                            <Input
                                onChange={handleChange}
                                id="lakesAndFishReport"
                                name="lakesAndFishReport"
                                type="textarea"
                                className="sploa-form-textarea"
                                value={formData.lakesAndFishReport}
                            // style={{ minHeight: formData.lakesAndFishReport.split("\n").length * 48 + "px" }}


                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="treasurersReport">
                                Treasurer's Report:
                            </Label>
                            <Input
                                onChange={handleChange}
                                id="treasurersReport"
                                name="treasurersReport"
                                type="textarea"
                                className="sploa-form-textarea"
                                value={formData.treasurersReport}
                            // style={{ minHeight: formData.treasurersReport.split("\n").length * 48 + "px" }}


                            />
                        </FormGroup>


                        <FormGroup>
                            <Label for="newBusiness">
                                New Business:
                            </Label>
                            <Input
                                onChange={handleChange}
                                id="newBusiness"
                                name="newBusiness"
                                type="textarea"
                                className="sploa-form-textarea"
                                value={formData.newBusiness}
                            // style={{ minHeight: formData.newBusiness.split("\n").length * 48 + "px" }}


                            />
                        </FormGroup>

                        <FormGroup>
                            <Label for="outro">
                                Closing Remarks:
                            </Label>
                            <Input
                                contentEditable={true}
                                onChange={handleChange}
                                id="outro"
                                name="outro"
                                type="textarea"
                                className="sploa-form-textarea"
                                value={formData.outro}
                            // style={{ minHeight: formData.outro.split("\n").length * 48 + "px" }}


                            />
                        </FormGroup>
                        <div>
                            {prevFormData ? <Button color="primary" disabled={loading} onClick={handleSubmit}> Update  </Button > 
                            :<Button color="primary"  disabled={loading} onClick={handleSubmit}>Submit </Button>}

                            <Button color="primary" style={{margin: "0px 24px"}}disabled={loading} onClick={() => changeMode("")}> Back   </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
}