import {
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Spinner
} from 'reactstrap';

import React, { useState, useEffect } from 'react';
import postData from '../utils/service'
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

export default function Agenda({ loggedIn }) {
    const [agenda, setAgenda] = useState({
        agendaBody: [],
        agendaHeader: []
    });
    const [mode, setMode] = useState("");
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await postData("retrieveAgenda", {});
                setAgenda(response);

            } catch (error) {
                console.error('Error fetching meeting minutes:', error);
                // Handle the error as needed
            }
        };

        fetchData();
    }, {});

    function formatText(str) {
        const className = {
            whiteSpace: 'pre-wrap',
            marginBottom: "4px",
            marginTop: "4px"

        }
        if (removeBString(str)) {
            str = removeBString(str);
            className.fontWeight = 600;
        }
        if (removeULString(str)) {
            str = removeULString(str);
            className.textDecoration = "underline";
        }
        const tabFunction = replaceTabs(str);
        str = tabFunction.str;
        className.marginLeft = tabFunction.marginLeft;
        return <div style={className} > {str} </div>
    }


    function removeBString(str) {
        if (str.includes("[b]")) {
            return str.replace("[b]", "");
        }

        return false;
    }
    function removeULString(str) {
        if (str.includes("[ul]")) {
            return str.replace("[ul]", "");
        }

        return false;
    }

    function replaceTabs(str) {
        const count = countOccurrences(str, "[tab]");
        return {
            str: str.replaceAll("[tab]", ""),
            marginLeft: count * 24 + "px"
        }
    }
    function countOccurrences(str, target) {
        const occurrences = str.split(target).length - 1;
        return occurrences;
    }

    const handleChange = (event) => {
        const { id, value } = event.target;
        console.log(id)
        console.log(JSON.stringify(value))
        setAgenda({ ...agenda, [id]: value.split("\n") });
    };

    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();
        console.log(agenda);
        
        await postData("updateAgenda", agenda);
        await postData("retrieveAgenda", {});
        setMode("");
        setLoading(false);

    }

    const [pdfName, setPdfName] = useState("");

    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    const [viewAll, setViewAll] = useState(false)

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    const options = {
        cMapUrl: '/cmaps/',
        standardFontDataUrl: '/standard_fonts/',
    };

    return (
        <>
            <div class="sploa-agenda-form">



                <div className="sploa-pdf-page">
                    {true ? <Document file={`Agenda.pdf`} onLoadSuccess={onDocumentLoadSuccess} options={options}>
                        {viewAll ?
                            Array.from(new Array(numPages), (el, index) => (
                                <Page className={''} key={`page_${1}`} pageNumber={1} />
                            ))
                            :
                            <Page className={''} pageNumber={1} />}

                    </Document> : <div> </div>}
                </div>




                {/*{mode === "edit" && <>*/}
                {/*    <div style={{margin: "24px 0"}}>*/}

                {/*        <div> <b>Formatting Tips</b> </div>*/}

                {/*        <div> Add [b] on a line to make it bold. </div>*/}
                {/*        <div>     Add [ul] on a line to underline it. </div>*/}
                {/*        <div>    Add [tab] to indent it a tab. You can add multiple tabs.</div>*/}
                {/*        <div>   You can see a preview at the bottom. </div>*/}

                {/*    </div>*/}



                {/*    <Form>*/}
                {/*        <FormGroup>*/}
                {/*            <Label for="bmAttending">*/}
                {/*                Agenda Header*/}
                {/*            </Label>*/}
                {/*            <Input*/}
                {/*                onChange={handleChange}*/}
                {/*                id="agendaHeader"*/}
                {/*                name="agendaHeader"*/}
                {/*                type="textarea"*/}
                {/*                style={{ minHeight: agenda.agendaHeader.length * 28 + "px" }}*/}
                {/*                className="sploa-form-agenda-textarea"*/}

                {/*                value={agenda.agendaHeader.join("\n")}*/}

                {/*            />*/}
                {/*        </FormGroup>*/}

                {/*        <FormGroup>*/}
                {/*            <Label for="bmAttending">*/}
                {/*                Agenda Body*/}
                {/*            </Label>*/}
                {/*            <Input*/}
                {/*                onChange={handleChange}*/}
                {/*                id="agendaBody"*/}
                {/*                name="agendaBody"*/}
                {/*                type="textarea"*/}
                {/*                style={{ minHeight: agenda.agendaBody.length * 26 + "px" }}*/}
                {/*                className="sploa-form-agenda-textarea"*/}

                {/*                value={agenda.agendaBody.join("\n")}*/}

                {/*            />*/}
                {/*        </FormGroup>*/}
                {/*    </Form>*/}
                {/*    <div style={{display:"flex"}}>*/}
                {/*    <Button disabled={loading} className={'agenda-button'} onClick={() => { setMode("") }}>*/}
                {/*            Cancel*/}
                {/*        </Button>*/}
                        
                {/*        <Button  disabled={loading}  color="primary" className={'agenda-button'} onClick={handleSubmit}>*/}
                {/*            Update*/}
                {/*        </Button>*/}

                {/*    </div>*/}
                {/*</>}*/}
                {/*{loggedIn && mode !== "edit" && <div className='sploa-agenda-edit'> <Button  disabled={loading}  color="primary" onClick={() => { setMode("edit") }} className={'sploa-minute-meeting-button'}> Edit </Button></div>}*/}
                {/*{Object.entries(agenda.agendaHeader).length === 0 && <Spinner color="primary"style={{*/}
                {/*    height: '4rem',*/}
                {/*    width: '4rem',*/}
                {/*    marginTop: "48px auto",*/}
                {/*    display: 'flex',*/}
                {/*    margin: 'auto'*/}
                {/*}}>*/}
                {/*</Spinner>}*/}
                {/*<div className='sploa-agenda-header'>*/}
                {/*    {agenda && agenda.agendaHeader && agenda.agendaHeader.map(item => {*/}
                {/*        return formatText(item)*/}
                {/*    })}*/}

                {/*</div>*/}

                {/*<div className='sploa-agenda-body'>*/}
                {/*    {agenda && agenda.agendaBody && agenda.agendaBody.map(item => {*/}
                {/*        return formatText(item)*/}
                {/*    })}*/}

                {/*</div>*/}


            </div>
        </>
    );
}