
export default function PolicyUnpaidAssessment() {
    return (
        <>
            <div class="sploa-info-page">

                <div className='sploa-flex-center sploa-title-header'>SPLOA POLICIES</div>
                <div className='sploa-flex-center sploa-title-header'>REGARDING COLLECTION OF UNPAID ASSESSMENTS</div>


                <div className='sploa-body'>

                    <div>
                        <div>
                            <li>
                                Regular assessments are to be paid by January 15th and will be considered past due and delinquent if not received by February 15th. Special assessments are to be paid within 30 days of being levied and will be considered past due and delinquent if not received within 60 days of levy. Regular and special assessments include any associated fees, charges, late charges, attorney fees, fines, and interest charged pursuant to C.R.S 38-33.3-315(2).
                            </li> <br/>
                            <li>
                                A fee of $25 or the amount charged by SPLOA’s bank, whichever is less, will be charged on all returned checks.
                            </li> <br/>
                            <li>
                                After February 15th, a letter will be sent to those property owners who have not made their payment stating that their account is overdue and will be assessed a late fee of $25 and interest of 18% per year.
                            </li> <br/>
                            <li>
                                Where required by statute, SPLOA will make a good-faith effort to coordinate with the property owners to set up a payment plan that meets the following requirements. SPLOA is not obligated to negotiate a payment plan with property owners who have previously entered into a payment plan.
                            </li> <br/>
                            <li>
                                Payment plan requirements:
                                <ol>
                                    <li>The payment plan will be for a period of at least six months.</li>
                                    <li>Payments must be in an amount sufficient to pay off the deficiency in equal installments over a six-month period.</li>
                                    <li>Agreed payments must be made in full and on time.</li>
                                    <li>In addition, property owners must make payments on the plan as they come due, and make timely payments and stay current with regular assessments as they come due during the six-month period. If the property owners do not stay current with the plan payments and with any other regular assessment payments, SPLOA has the right to immediately proceed with legal actions to collect the debt.</li>
                                </ol>
                            </li>
                            <li>
                                Property owners are not entitled to enter into a payment plan with SPLOA if:
                                <ol>
                                    <li>The property owners have previously entered into a payment plan regarding the same assessment.</li>
                                    <li>The property owners do not occupy the property, and have acquired the property as a result of either:
                                        <ol>
                                            <li>A default of a security interest encumbering the property.</li>
                                            <li>Foreclosure of the association’s lien.</li>
                                        </ol>
                                    </li>
                                </ol>
                            </li> 
                            <li>
                                If the debt has not been satisfied in full by September 15, including payment of all applicable late fees, interest, and attorneys’ fees and costs, if any, the SPLOA board may approve the filing of a foreclosure or other collection action.
                            </li> <br/>
                            <li>
                                SPLOA shall not foreclose its lien against a property owner unless the balance due equals or exceeds an amount equal to 6 months of assessments, including items in addition to assessment fees, interest, late fees, fines, attorneys’ fees, and other charges permitted to be charged by SPLOA’s governing documents.
                            </li> <br/>
                            <li>
                                Notice of delinquency. Before SPLOA turns over the delinquent account of property owners to a collection agency or refers it to an attorney for legal action, SPLOA shall send the property owners a notice of delinqu

                                ency specifying:
                                <ol>
                                    <li>The total amount due, with an accounting of how the total was determined.</li>
                                    <li>Whether the opportunity to enter into a payment plan exists pursuant to applicable statutes, and instructions for contacting SPLOA to enter into such a payment plan.</li>
                                    <li>The name and contact information for the individual the property owners may contact to request a copy of the property owner's ledger in order to verify the amount of the debt.</li>
                                    <li>That action is required to cure the delinquency and that failure to do so within thirty days may result in the unit owner's delinquent account being turned over to a collection agency, a lawsuit being filed against the owner, the filing and foreclosure of a lien against the unit owner's property, or other remedies available under Colorado law.</li>
                                    <li>The method by which payments may be applied to the property owners’ delinquent account.</li>
                                    <li>The legal remedies available to SPLOA to collect on the property owners’ delinquent account pursuant to SPLOA’s governing documents and Colorado law.</li>
                                </ol>
                            </li>
                            <li>
                                Authorization of legal action. Before taking such legal action, and by formal resolution and recorded vote, SPLOA’s board of directors must authorize any legal action to be taken against the property owners’ specific property or the property owners individually. The board of directors may not delegate this responsibility to an attorney, insurer, manager, or any other person.
                            </li> <br/>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}